import memoize from "@RHCommerceDev/utils/memoize";
import classNames from "classnames";
import React, { ChangeEvent, FC } from "react";

export type TailwindRHRadioProps = {
  customClass?: string;
  checked?: boolean;
  onChange?:
    | ((event?: ChangeEvent<HTMLInputElement>, checked?: boolean) => void)
    | undefined;
  className?: string;
};

export const TailwindRHRadio: FC<TailwindRHRadioProps> = ({
  checked,
  onChange,
  ...rest
}) => {
  const baseId = "radio-input-component";
  const props = {
    disableRipple: true,
    checkedIcon: (
      <span
        className={classNames(
          "relative w-6 h-6 rounded-[15px] bg-white border-8 border-solid",
          "before:content-[''] before:block before:absolute before:w-[10px] before:h-[10px] before:top-1/2 before:left-1/2 before:rounded-[7px] before:bg-black before:transform before:-translate-x-1/2 before:-translate-y-1/2",
          rest?.customClass
        )}
      />
    ),
    icon: (
      <span
        className={`w-6 h-6 rounded-[15px] bg-white border-solid border-8 ${rest?.customClass}`}
      />
    ),
    ...rest,
    className: classNames(
      "p-2.5 ml-0.5 rounded-none focus:outline-solid focus:outline-2 focus:outline-[#1976d2] focus:outline-offset-2 flex ",
      rest.className
    )
  };

  return (
    <label className={props.className}>
      <input
        id={baseId}
        data-testid={baseId}
        type="radio"
        className="sr-only"
        checked={true}
        onChange={onChange}
      />
      {checked ? props.checkedIcon : props.icon}
    </label>
  );
};

TailwindRHRadio.defaultProps = {};

export default memoize(TailwindRHRadio);
