import {
  Grid,
  GridList,
  GridListTile,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  Modal,
  Box,
  Popper,
  PopperPlacementType,
  Button
} from "@material-ui/core";
import RHImage from "@RHCommerceDev/component-rh-image";
import React, { FC, useCallback, useState, CSSProperties } from "react";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS,
  IMAGE_ASPECT_RATIO,
  SEE_ALL_OPTIONS_TOOLTIP,
  UPDATE_OTHER_OPTIONS_TOOLTIP
} from "utils/constants";
import memoize from "utils/memoize";
import { usePageContent } from "customProviders/LocationProvider";
import { Tooltip, Typography } from "utils/material-ui-core";
import classNames from "classnames";
import RHCloseIcon from "icons/RHCloseIcon";
import AddToCartDialog from "dialogs/AddToCartDialog";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import TailwindPopper from "component-tailwind-popper";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    fabricGrid: {
      marginTop: "8px"
    },
    saleText: {
      color: "#CA2022",
      fontFamily: "RHSans-Light",
      fontSize: "11px",
      lineHeight: "120%",
      letterSpacing: "0.44px",
      textAlign: "center",
      marginLeft: "5px"
    },
    tootltipStyle: {
      flexGrow: 0,
      margin: "4px 0 4px -4px",
      borderRadius: "0",
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: "var(--Reimagine-White,#fff)",

      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        width: "343px !important",
        padding: "8px 0"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: "343px !important",
        padding: "8px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: "319px !important",
        padding: "8px 2px 8px 0"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: "347px !important",
        padding: "8px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        width: "371px !important",
        padding: "7px 8.5px 10px 6px"
      }
    },
    swatchImage: {
      flexGrow: 0,

      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        width: "327px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: "327px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: "303px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: "329.6px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        width: "357px",
        height: "244px !important"
      }
    },
    tooltipTitle: {
      fontFamily: "RHSans-ExtraLight",
      fontSize: "13px",
      lineHeight: "14.3px",
      letterSpacing: "0.26px",
      color: "#393c41",
      padding: "7px 8px 12px 6px"
    },

    buttonStyle: {
      position: "absolute",

      top: "10px",
      zIndex: 9999,
      width: "26px",
      height: "26px",
      padding: "9px",
      borderRadius: "50px",
      backgroundColor: "var(--Reimagine-White,#fff)",
      border: "solid 0.5px var(--reimagine-warm-rh-medium)",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        right: "12px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        right: "4px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        right: "15px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        right: "9.6px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        right: "18px"
      }
    },
    detailsButton: {
      width: "100%",
      backgroundColor: "white",
      border: "0.27px solid var(--ReimagineWarm-RH-Dark, #898886)",
      height: "48px",
      zIndex: 99999999,
      margin: "10 10 0 10px",
      cursor: "pointer",
      lineHeight: "13.2px !important",
      letterSpacing: "0.44px !important",
      fontSize: "11px !important",
      "&:focus-visible": {
        outline: "#015fcc auto 1px"
      }
    },
    addSwatchToCart: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "BaronSans",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: "0.44px",
      width: "100%",
      padding: "18px 0 17px 0 !important",

      [theme.breakpoints.down(BREAKPOINT_XL)]: {
        padding: "17px 0 18px 0 !important"
      },

      [theme.breakpoints.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
        padding: "17px 0 17px 0 !important"
      }
    },
    addSwatchToCartDetail: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "BaronSans-Light",
      fontSize: "12.508px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: "0.5px",
      width: "100%",
      padding: "14px 0 15px 0 !important",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        maxWidth: "404px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        maxWidth: "282px",
        color: "#FFF",
        fontSize: "11px",
        letterSpacing: "0.44px",
        padding: "13px 0 14px 0 !important"
      }
    },
    detailsButtonBlack: {
      color: "white",
      backgroundColor: "#000",
      textTransform: "unset",
      "&:hover": {
        backgroundColor: "#404040",
        borderColor: "#404040"
      }
    },
    titleGrid: {
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        maxWidth: "327px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        maxWidth: "327px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        maxWidth: "303px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        maxWidth: "329.6px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        maxWidth: "357px"
      }
    },
    buttonGrid: {
      display: "flex",
      gap: "7.5px",

      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        maxWidth: "327px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        maxWidth: "327px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        maxWidth: "303px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        maxWidth: "329.6px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        maxWidth: "371px"
      }
    },
    imageGrid: {
      display: "flex",

      justifyContent: "center",
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        width: "327px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: "327px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: "303px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: "329.6px",
        height: "243px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        width: "357px",
        height: "244px !important"
      }
    },
    popSwatchTitleStyle: {
      flexGrow: 0,
      fontFamily: "RHSans-ExtraLight",
      fontSize: "18.2px",
      fontWeight: 200,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "0.36px",
      color: "#393c41",
      marginTop: "7.1px"
    },
    imageContainerClass: {
      width: "238px !important",
      height: "237px !important",
      flexGrow: 0,
      [theme.breakpoints.up("xs")]: {
        width: "238px !important",
        height: "237px !important",
        flexGrow: 0
      },
      [theme.breakpoints.up("sm")]: {
        width: "286px !important",
        height: "286px !important",
        flexGrow: 0
      },
      [theme.breakpoints.up("md")]: {
        width: "400px !important",
        height: "400px !important",
        flexGrow: 0
      },
      [theme.breakpoints.up("lg")]: {
        width: "400px",
        height: "400px !important",
        flexGrow: 0
      },
      [theme.breakpoints.up("xl")]: {
        width: "400px",
        height: "400px !important",
        flexGrow: 0
      }
    },
    swatchStyles: {
      height: "32px !important",
      width: "32px !important",
      [theme.breakpoints.up("xs")]: {
        height: "32px !important",
        width: "32px !important"
      },
      [theme.breakpoints.up("sm")]: {
        height: "32px !important",
        width: "32px !important"
      },
      [theme.breakpoints.up("md")]: {
        height: "46.6px !important",
        width: "46.6px !important"
      },
      [theme.breakpoints.up("lg")]: {
        height: "46.6px !important",
        width: "46.6px !important"
      },
      [theme.breakpoints.up("xl")]: {
        height: "46.6px !important",
        width: "46.6px !important"
      }
    },
    popUpContainer: {
      width: "357px",
      flexGrow: 0,
      [theme.breakpoints.up("xs")]: {
        width: "375px",
        flexGrow: 0
      },
      [theme.breakpoints.up("sm")]: {
        width: "617px",
        flexGrow: 0
      },
      [theme.breakpoints.up("md")]: {
        width: "900px",
        flexGrow: 0
      },
      [theme.breakpoints.up("lg")]: {
        width: "900px",
        flexGrow: 0
      },
      [theme.breakpoints.up("xl")]: {
        width: "900px",
        flexGrow: 0
      }
    },
    popUpGridContainer: {
      padding: "32px 32px 32px 32px",
      [theme.breakpoints.only("xs")]: {
        padding: "0px 16px 40.6px 16px"
      },
      [theme.breakpoints.only("sm")]: {
        padding: "0px 32px 39.6px 32px"
      }
    },
    romanceCopyStyles: {
      margin: "0px",
      flexGrow: 0,
      fontFamily: "RHSans-Thin",
      fontSize: "14.8px",
      fontWeight: 100,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "0.59px",
      textAlign: "left",
      color: "var(--reimagine-warm-rh-black)",
      paddingBottom: "24px",
      "& strong": {
        fontFamily: "RHSans-Light",

        fontSize: "20px",
        fontWeight: 300,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "0.4px",
        color: "var(--reimagine-warm-rh-black)"
      }
    },
    customTooltip: {
      padding: "8px 10px",
      color: "var(--reimagine-warm-rh-white, #FFF)",
      fontSize: "11px",
      fontFamily: "RHSans-Light",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "-0.11px",
      zIndex: 1,
      background: "#898886",
      fontWeight: 300
    },
    arrow: {
      color: "#898886"
    },
    diagonalStrick: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(-45deg, transparent 49.5%, #FFF 49.5%, #fff 50.5%, transparent 50.5%)"
    }
  });
});
interface SwatchConfiguratorProps {
  swatchGroupName?: string | null;
  groupMaterial?: string | null;
  swatches?: any[] | null;
  handleSelection?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selection?: any | null;
  data?: Product;
  checked?: boolean;
  isAllSwatchesOnSale?: boolean;
  isSwatchClicked?: boolean;
  setIsSwatchClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  openStatus?: {};
  setOpenStatus?: React.Dispatch<React.SetStateAction<{}>>;
  activeIndex?: number;
  setActiveIndex?: React.Dispatch<React.SetStateAction<number>>;
}

export const SwatchesConfigurator: FC<SwatchConfiguratorProps> = memoize(
  ({
    swatchGroupName,
    handleSelection,
    selection,
    swatches,
    groupMaterial,
    isAllSwatchesOnSale,
    openStatus,
    setOpenStatus,
    activeIndex,
    setActiveIndex
  }) => {
    const env = useEnv();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedSwatch, setSelectedSwatch] = useState<any>(null);
    const [addScroll, setAddScroll] = useState<boolean>(false);
    const [isPostion, setIsPostions] = useState<boolean>(false);
    const classes = useStyles();
    const { pageContent } = usePageContent();
    const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
    const DefaulPopper = FEATURE_TAILWIND_COMPONENTS ? TailwindPopper : Popper;
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const [anchorEl, setAnchorEl] = useState(null);
    const [swatchSelected, setSwatchSelected] = useState<any>(null);
    const [isToolTipMessage, setIsToolTipMessage] = useState(
      SEE_ALL_OPTIONS_TOOLTIP
    );

    const [cartDialog, setCartDialog] = useState(false);
    const [cartDetail, setCartDetail] = useState({
      sku: "",
      swatchProductId: ""
    });
    const [confirmed, setConfirmed] = useState(false);

    const [tooltipPlacement, setTooltipPlacement] = useState<
      PopperPlacementType | undefined
    >("top-start");
    const handleClickSwatchs = () => {
      setIsToolTipMessage(UPDATE_OTHER_OPTIONS_TOOLTIP);
    };

    const handleMouseEnter = useCallback(
      index => {
        setActiveIndex(index);
        setIsToolTipMessage(SEE_ALL_OPTIONS_TOOLTIP);
      },
      [setActiveIndex]
    );

    const handleMouseLeave = useCallback(() => {
      setActiveIndex(null);
      setIsToolTipMessage(SEE_ALL_OPTIONS_TOOLTIP);
    }, []);

    const style = {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#fff",
      boxShadow: "24",
      p: "4",
      overflow: addScroll ? "scroll" : "none",
      height: addScroll ? "100%" : "auto"
    } as CSSProperties;

    const handleClickSwatch = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClosePopper = useCallback(() => {
      setOpenStatus({});
    }, [setOpenStatus]);

    const handleSwatch = useCallback(
      (productSwatchData, groupMaterial, event) => {
        if (event.clientY < 550) {
          setTooltipPlacement("bottom-start");
        } else {
          setTooltipPlacement("top-start");
        }
        if (
          !HIDE_SWATCH_DETAILS_MODAL_FOR_OPTIONS?.includes(
            groupMaterial?.toString()?.toLowerCase()
          )
        ) {
          setOpenStatus(prevStatus => {
            const newStatus = {};
            Object.keys(prevStatus).forEach(key => {
              newStatus[key] = false;
            });
            newStatus[productSwatchData.swatchId] = true;
            return newStatus;
          });
        }

        setSwatchSelected(productSwatchData);
      },
      [setOpenStatus]
    );

    return (
      <Grid
        item
        xs={12}
        className={classes?.fabricGrid}
        data-testid="rh-swatches-configurator"
        onClick={event => {
          handleClickSwatch(event);
        }}
        onMouseEnter={event => {
          handleClickSwatch(event);
        }}
      >
        <Modal
          data-testid="rh-swatches-modal"
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style} className={classes.popUpContainer}>
            <div
              style={{ background: "#fff", height: "auto" }}
              ref={(ref: HTMLDivElement) => {
                if (!isPostion && openModal && ref?.offsetHeight) {
                  const modalHeight = ref?.offsetHeight;
                  const windowHeight = window.innerHeight;
                  setAddScroll(modalHeight > windowHeight);
                  setIsPostions(true);
                }
              }}
            >
              {smDown && (
                <Grid
                  xs={12}
                  style={{
                    background: "#fff",
                    height: xsDown ? "19px" : "32px",
                    display: "flex",
                    alignContent: "center",
                    flexDirection: "row-reverse",
                    flexWrap: "wrap"
                  }}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <RHCloseIcon
                    style={{
                      width: "18.2px",
                      height: "18.2px",
                      color: "black",
                      float: "right",
                      marginRight: xsDown ? "9px" : "13.9px",
                      marginTop: xsDown ? "9px" : "13.9px"
                    }}
                  />
                </Grid>
              )}
              <div
                className={classes.popUpGridContainer}
                style={{
                  background: "#fff"
                }}
              >
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  style={{
                    flexWrap: smDown ? "wrap" : "unset",
                    gap: smDown ? "24px" : "32px",
                    margin: 0
                  }}
                >
                  <Grid item xs={12} sm={12} md={6} style={{ padding: 0 }}>
                    <Grid xs={12}>
                      <div className={classes.imageContainerClass}>
                        <RHImage
                          imageStyles={{
                            alignItems: "start",
                            justifyContent: "start"
                          }}
                          src={selectedSwatch?.swatchImageLg?.url}
                          alt={`Swatch ${selectedSwatch?.title}`}
                          aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                          imageClassName={classNames(
                            classes.imageContainerClass
                          )}
                        />
                      </div>
                      <span>
                        <Typography className={classes.popSwatchTitleStyle}>
                          {selectedSwatch?.title}
                        </Typography>
                      </span>
                    </Grid>
                    <Grid
                      xs={12}
                      style={{ marginTop: xsDown ? "24px" : "18.7px" }}
                    >
                      <GridList
                        cols={10}
                        cellHeight="auto"
                        spacing={3}
                        style={{ gap: "4px" }}
                      >
                        {(swatches ?? []).map((productSwatch, index) => {
                          return (
                            <GridListTile
                              key={index}
                              className={classes.swatchStyles}
                              onClick={event => {
                                setSelectedSwatch(productSwatch);
                                handleSelection({
                                  ...productSwatch,
                                  swatchGroupName: swatchGroupName,
                                  groupMaterial: groupMaterial
                                });
                              }}
                              style={{
                                padding: 0
                              }}
                            >
                              <RHImage
                                imageClassName={classes.swatchStyles}
                                imageWrapperStyles={{
                                  border:
                                    selection?.swatchId ===
                                      productSwatch?.swatchId ||
                                    productSwatch?.status === "selected"
                                      ? "1px solid var(--ReimagineWarm-RH-Dark, #000)"
                                      : "0.27px solid var(--ReimagineWarm-RH-Dark, #898886)"
                                }}
                                style={{
                                  padding:
                                    selection?.swatchId ===
                                    productSwatch?.swatchId
                                      ? "2px"
                                      : "0px"
                                }}
                                src={productSwatch?.swatchImageLg?.url}
                                alt={`Swatch ${productSwatch?.title}`}
                                aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                              />
                              {productSwatch?.onSale &&
                                !isAllSwatchesOnSale && (
                                  <span className={classes?.saleText}>
                                    {pageContent?.sale}
                                  </span>
                                )}
                            </GridListTile>
                          );
                        })}
                      </GridList>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} style={{ padding: 0 }}>
                    <Grid xs={12}>
                      {!smDown && (
                        <span
                          onClick={() => {
                            setOpenModal(false);
                          }}
                        >
                          <RHCloseIcon
                            style={{
                              width: "18.2px",
                              height: "18.2px",
                              color: "black",
                              float: "right"
                            }}
                          />
                        </span>
                      )}
                      <p
                        className={classes.romanceCopyStyles}
                        dangerouslySetInnerHTML={{
                          __html: selectedSwatch?.optionCopy
                        }}
                      />
                      {swatchSelected?.swatchSkuIds?.[0]?.swatchProductId ? (
                        <Button
                          variant="contained"
                          className={classNames(
                            classes.detailsButtonBlack,
                            classes.addSwatchToCartDetail
                          )}
                          style={{ border: "0" }}
                          disabled={
                            !swatchSelected?.swatchSkuIds?.[0]?.swatchProductId
                          }
                          onClick={() => {
                            setCartDialog(true);
                            setCartDetail({
                              sku: swatchSelected?.swatchSkuIds?.[0]?.skuIds,
                              swatchProductId:
                                swatchSelected?.swatchSkuIds?.[0]
                                  ?.swatchProductId
                            });
                            setOpenModal(false);
                            handleClosePopper();
                          }}
                        >
                          {pageContent?.ADD_SWATCH_TO_CART}
                        </Button>
                      ) : null}
                    </Grid>
                    {/* <Grid xs={12} style={{ marginTop: "18.7px" }}>
                     <Button
            style={{
              width: "100%",
              backgroundColor: "#000",
              color: "#fff"
            }}
          >
            {pageContent.ADD_SWATCH_TO_CART}
          </Button> 
                  </Grid> */}
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </Modal>

        <DefaulPopper
          open={openStatus?.[swatchSelected?.swatchId]}
          anchorEl={anchorEl}
          disablePortal
          placement={tooltipPlacement}
          transition
          style={{ zIndex: "9999" }}
        >
          <Box className={classes.tootltipStyle}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{ position: "relative" }}
                className={classes.imageGrid}
              >
                <div
                  className={classes.buttonStyle}
                  onClick={handleClosePopper}
                >
                  <RHCloseIcon
                    style={{
                      width: "8px",
                      height: "8px",
                      color: "black",
                      cursor: "pointer"
                    }}
                  />
                </div>

                <RHImage
                  src={swatchSelected?.swatchImageLg?.url}
                  alt={`Swatch ${swatchSelected?.title}`}
                  aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                  imageClassName={classNames(classes.swatchImage)}
                  imageStyles={{ height: "none" }}
                  heroImageStyles={classNames(classes.swatchImage)}
                  isSwatchTooltip="true"
                />
              </Grid>
              <Grid xs={12} md={12} className={classes.titleGrid}>
                <Typography className={classes.tooltipTitle}>
                  {swatchSelected?.title}
                </Typography>
              </Grid>
              <Grid xs={12} md={12} className={classes.buttonGrid}>
                <button
                  className={classes.detailsButton}
                  data-testid="rh-view-details-button"
                  onClick={event => {
                    setSelectedSwatch(swatchSelected);
                    setOpenModal(true);

                    handleClosePopper();
                  }}
                >
                  {pageContent.VIEW_DETAILS}
                </button>
                {swatchSelected?.swatchSkuIds?.[0]?.swatchProductId ? (
                  <Button
                    variant="contained"
                    className={classNames(
                      classes.addSwatchToCart,
                      classes.detailsButtonBlack
                    )}
                    style={{ border: "0", maxWidth: "174.5px" }}
                    data-testid="rh-add-to-cart-button"
                    disabled={
                      !swatchSelected?.swatchSkuIds?.[0]?.swatchProductId
                    }
                    onClick={() => {
                      setCartDialog(true);
                      setCartDetail({
                        sku: swatchSelected?.swatchSkuIds?.[0]?.skuIds,
                        swatchProductId:
                          swatchSelected?.swatchSkuIds?.[0]?.swatchProductId
                      });
                      handleClosePopper();
                    }}
                  >
                    {pageContent?.ADD_SWATCH_TO_CART}
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </DefaulPopper>
        {cartDialog && (
          <AddToCartDialog
            open={cartDialog}
            fullSkuId={cartDetail?.sku}
            qty={1}
            productId={cartDetail?.swatchProductId}
            setConfirmed={setConfirmed}
            confirmed={confirmed}
            onClose={() => setCartDialog(false)}
          />
        )}

        <GridList
          cols={10}
          cellHeight="auto"
          spacing={3}
          style={{ gap: "4px", cursor: "pointer" }}
        >
          {(swatches ?? []).map((productSwatch, index) => {
            return productSwatch?.status !== "unavailable" ? (
              <GridListTile
                key={index}
                onClick={event => {
                  handleSelection({
                    ...productSwatch,
                    swatchGroupName: swatchGroupName,
                    groupMaterial: groupMaterial
                  });
                  handleSwatch(productSwatch, groupMaterial, event);
                }}
                style={{
                  height: "auto",
                  width: "32px",
                  padding: 0
                }}
                id={`options-selector-li-${productSwatch.swatchId}`}
                data-testid="rh-swatch-grid"
              >
                <RHImage
                  imageWrapperStyles={{
                    border:
                      selection?.swatchId === productSwatch?.swatchId ||
                      productSwatch?.status === "selected"
                        ? "1px solid var(--ReimagineWarm-RH-Dark, #000)"
                        : "0.27px solid var(--ReimagineWarm-RH-Dark, #898886)"
                  }}
                  style={{
                    transform:
                      productSwatch?.status === "selected"
                        ? "scale(0.89)"
                        : "scale(1)"
                  }}
                  src={productSwatch?.swatchImageLg?.url}
                  alt={`Swatch ${productSwatch?.title}`}
                  aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                />
                {productSwatch?.onSale && !isAllSwatchesOnSale && (
                  <span className={classes?.saleText}>{pageContent?.sale}</span>
                )}
              </GridListTile>
            ) : (
              <Tooltip
                open={activeIndex === productSwatch?.swatchId}
                title={
                  isToolTipMessage?.length
                    ? isToolTipMessage
                    : SEE_ALL_OPTIONS_TOOLTIP
                }
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.arrow
                }}
                arrow
                placement={
                  tooltipPlacement === "bottom-start" ? "bottom" : "top"
                }
                enterDelay={500}
                leaveDelay={2000}
              >
                <GridListTile
                  key={index}
                  id={`unavailable-finishSwatches-images-${productSwatch?.swatchId}`}
                  style={{
                    width: "32px",
                    height: "32px",
                    border:
                      productSwatch?.status === "selected"
                        ? "0.25px solid var(--ReimagineWarm-RH-Dark, #898886)"
                        : ""
                  }}
                  onClick={event => {
                    event.stopPropagation();
                    handleSwatch(productSwatch, groupMaterial, event);
                    handleClickSwatchs();
                    handleSelection({
                      ...productSwatch,
                      swatchGroupName: swatchGroupName,
                      groupMaterial: groupMaterial
                    });
                  }}
                  onMouseEnter={() => {
                    handleMouseEnter(productSwatch?.swatchId);
                  }}
                  onMouseLeave={handleMouseLeave}
                >
                  <RHImage
                    src={productSwatch?.swatchImageLg?.url}
                    alt={`Swatch ${productSwatch?.title}`}
                    aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                    style={{ width: "32px", height: "32px" }}
                  />
                  <div className={classes.diagonalStrick} />
                </GridListTile>
              </Tooltip>
            );
          })}
        </GridList>
      </Grid>
    );
  }
);
