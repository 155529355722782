import React, { FC } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  useMediaQuery,
  Theme,
  Typography,
  useTheme
} from "utils/material-ui-core";
import RHImage from "component-rh-image";
import useTypographyStyles from "hooks/useTypographyStyles";
import { IMAGE_ASPECT_RATIO, CATALOG_SALE_COLOR } from "utils/constants";
import memoize from "utils/memoize";
import { usePageContent } from "customProviders/LocationProvider";
import classnames from "classnames";

export interface SwatchInfoMobileProps {
  listItemClassName?: string;
  swatchGroup: ProductSwatchGroup;
  productSwatches: ProductSwatch[];
  selectedSwatches: {
    property?: ProductSwatch;
  } | null;
  onClick?: {
    (data: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }): void;
  };
  isCustomProduct?: boolean;
  setShowSwatchDetailDialog: (showSwatchDetailDialog: boolean) => void;
  selectedOptionsFromLineItem?: { [key: string]: any };
  isOnlyColor?: boolean;
}

const SwatchInfoMobile: FC<SwatchInfoMobileProps> = ({
  listItemClassName,
  swatchGroup,
  productSwatches,
  selectedSwatches,
  onClick,
  isCustomProduct,
  setShowSwatchDetailDialog,
  selectedOptionsFromLineItem,
  isOnlyColor
}) => {
  const theme = useTheme();
  const { pageContent } = usePageContent();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseBody2", "rhBaseBodyRoman"]
  });
  const [anchorSwatchInfo, setanchorSwatchInfo] =
    React.useState<null | HTMLLIElement>(null);

  const openSwatchInfo = (event: React.MouseEvent<HTMLLIElement>) => {
    setanchorSwatchInfo(anchorSwatchInfo ? null : event.currentTarget);
    setShowSwatchDetailDialog(!!event.currentTarget);
  };

  React.useEffect(() => {
    if (isCustomProduct) {
      onClick?.({
        swatchGroup,
        selectedSwatch: productSwatches[0]
      });
    }
  }, []);

  return (
    <Grid item xs={12} className={listItemClassName}>
      {!isCustomProduct ? (
        <Typography paragraph className={typographyClasses.rhBaseBody1}>
          {swatchGroup.swatchGroupName}
        </Typography>
      ) : null}
      <GridList
        cols={smDown ? 4 : 6}
        cellHeight="auto"
        spacing={smDown ? 17 : 20}
      >
        {productSwatches.map((productSwatch, index) => {
          const selected =
            selectedOptionsFromLineItem?.length > 0
              ? Object.entries(productSwatch.options).some(([index, item]) => {
                  const colorInfo = selectedOptionsFromLineItem.find(
                    swatch =>
                      swatch?.value?.toLowerCase() ===
                      productSwatch?.title?.toLowerCase()
                  );
                  const swatchMatched = Object.entries(
                    selectedOptionsFromLineItem
                  ).some(([index, lineitem]) => {
                    if (item?.id === lineitem?.id) {
                      if (!isOnlyColor) {
                        return (
                          lineitem["type"]?.toLowerCase() !== `color` &&
                          lineitem["type"]?.toLowerCase() ===
                            item?.optionType?.toLowerCase()
                        );
                      } else
                        return (
                          lineitem["type"]?.toLowerCase() ===
                          item?.optionType?.toLowerCase()
                        );
                    }
                  });
                  if (
                    item?.optionType?.toLowerCase() === "fabric" ||
                    item?.optionType?.toLowerCase() === "leather"
                  )
                    return swatchMatched && !!colorInfo;
                  else return swatchMatched;
                })
              : selectedSwatches &&
                Object.entries(selectedSwatches).some(
                  ([groupMaterial, swatch]) => {
                    return (
                      groupMaterial === swatchGroup?.groupMaterial &&
                      swatch?.swatchId === productSwatch?.swatchId
                    );
                  }
                );

          const swatchStockStatus = productSwatch?.onSale;

          return (
            <GridListTile
              key={`stockedSwatch-${index}`}
              onClick={event => {
                openSwatchInfo(event);
                onClick?.({
                  swatchGroup,
                  selectedSwatch: productSwatch
                });
              }}
              style={{ width: "25%" }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 0,
                  ...(selected
                    ? {
                        borderWidth: 1,
                        padding: 2
                      }
                    : {})
                }}
              >
                <RHImage
                  src={productSwatch.swatchImageLg.url}
                  alt={productSwatch.title}
                  aspectRatio={IMAGE_ASPECT_RATIO.swatch}
                />
              </div>
              <Typography
                className={typographyClasses.rhBaseBody1}
                style={{
                  paddingTop: 7
                }}
              >
                {productSwatch.title}
              </Typography>
              {swatchStockStatus && (
                <Typography
                  variant="body1"
                  className={classnames([
                    typographyClasses.rhBaseBody1,
                    typographyClasses.rhBaseBodyRoman
                  ])}
                  style={{
                    color: CATALOG_SALE_COLOR,
                    textTransform: "capitalize",
                    fontSize: "11px"
                  }}
                >
                  {pageContent?.sale}
                </Typography>
              )}
            </GridListTile>
          );
        })}
      </GridList>
    </Grid>
  );
};

SwatchInfoMobile.defaultProps = {};

export default memoize(SwatchInfoMobile);
