import React, { FC } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  Theme,
  Tooltip,
  useMediaQuery
} from "utils/material-ui-core";
import RHImage from "component-rh-image";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";
import classnames from "classnames";
import { IMAGE_ASPECT_RATIO, CATALOG_SALE_COLOR } from "utils/constants";
import memoize from "utils/memoize";
import { handleKeyboardPressWithEvent } from "utils/accessibility";

export interface SwatchDesktopInfoProps {
  onClick?: {
    (data: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }): void;
  };
  productSwatches: ProductSwatch[];
  selectedSwatches: {
    property?: ProductSwatch;
  };
  selectedOptionsFromLineItem?: { [key: string]: any };
  swatchGroup: ProductSwatchGroup;
  isCustomProduct?: boolean;
  isOnlyColor?: boolean;
}

const SwatchInfoDesktop: FC<SwatchDesktopInfoProps> = ({
  onClick,
  productSwatches,
  selectedSwatches,
  swatchGroup,
  isCustomProduct,
  selectedOptionsFromLineItem,
  isOnlyColor
}) => {
  const typographyClasses = useTypographyStyles({
    keys: [
      "rhBaseH5",
      "rhBaseCaption",
      "rhBaseBody2",
      "rhBaseBody1",
      "rhBaseBodyRoman"
    ]
  });
  const { pageContent } = usePageContent();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

  return (
    <Grid
      key={`swatch-info-desktop${swatchGroup.swatchGroupIndex}${swatchGroup.swatchGroupName}`}
      item
      xs={12}
    >
      {!isCustomProduct ? (
        <Typography className={typographyClasses.rhBaseBody1} paragraph>
          {swatchGroup.swatchGroupName || pageContent?.FINISH_OPTIONS}
        </Typography>
      ) : null}

      <GridList
        cols={smDown ? 4 : 6}
        cellHeight="auto"
        spacing={smDown ? 17 : 20}
      >
        {productSwatches.map((productSwatch, index) => {
          const selected =
            selectedOptionsFromLineItem?.length > 0
              ? Object.entries(productSwatch.options).some(([index, item]) => {
                  const colorInfo = selectedOptionsFromLineItem.find(
                    swatch =>
                      swatch?.value?.toLowerCase() ===
                      productSwatch?.title?.toLowerCase()
                  );
                  const swatchMatched = Object.entries(
                    selectedOptionsFromLineItem
                  ).some(([index, lineitem]) => {
                    if (item?.id === lineitem?.id) {
                      if (!isOnlyColor) {
                        return (
                          lineitem["type"]?.toLowerCase() !== `color` &&
                          lineitem["type"]?.toLowerCase() ===
                            item?.optionType?.toLowerCase()
                        );
                      } else
                        return (
                          lineitem["type"]?.toLowerCase() ===
                          item?.optionType?.toLowerCase()
                        );
                    }
                  });
                  if (
                    item?.optionType?.toLowerCase() === "fabric" ||
                    item?.optionType?.toLowerCase() === "leather"
                  )
                    return swatchMatched && !!colorInfo;
                  else return swatchMatched;
                })
              : Object.entries(selectedSwatches).some(
                  ([groupMaterial, swatch]) => {
                    return (
                      groupMaterial === swatchGroup?.groupMaterial &&
                      swatch?.swatchId === productSwatch?.swatchId
                    );
                  }
                );

          const title = productSwatch?.title?.split(" ");
          title?.splice(1, 0, "<br/>");
          const productSwatchTitle = title?.join(" ");
          const swatchStockStatus = productSwatch?.onSale;

          return (
            <Tooltip
              key={`swatch-tooltip${index}`}
              classes={{
                tooltip: !productSwatch.optionCopy
                  ? `!bg-white shadow-swatchGroupsToolTip pointer-events-none md:!max-w-[332px] lg:!max-w-[362px] xl:!max-w-[392px]`
                  : `!bg-white shadow-swatchGroupsToolTip md:!max-w-[800px] lg:!max-w-[860px] xl:!max-w-[900px] !max-w-[600px]`
              }}
              placement="top-start"
              title={
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={
                      !productSwatch.optionCopy || !productSwatch.title ? 12 : 5
                    }
                  >
                    <RHImage
                      imageClassName={classnames(
                        `md:!w-[270px] md:!h-[270px] lg:!w-[300px] lg:!h-[300px] xl:!w-[320px] xl:!h-[320px]`
                      )}
                      src={productSwatch.swatchImageLg.url}
                      alt={productSwatch.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Typography
                      className={classnames([
                        "[&>strong]:uppercase [&>strong]:text-black [&>strong]:font-medium  [&>strong]:text-[1rem] [&>strong]:font-primary-rhsans ",
                        typographyClasses.rhBaseCaption
                      ])}
                      dangerouslySetInnerHTML={{
                        __html: productSwatch.optionCopy || productSwatch.title
                      }}
                    />
                  </Grid>
                </Grid>
              }
            >
              <GridListTile
                key={`stockedSwatch-${index}`}
                className={`focus:outline-none`}
                onClick={() => {
                  onClick?.({
                    swatchGroup,
                    selectedSwatch: productSwatch
                  });
                }}
                tabIndex={0}
                onKeyDown={handleKeyboardPressWithEvent(() => {
                  onClick?.({
                    swatchGroup,
                    selectedSwatch: productSwatch
                  });
                })}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 0,
                    ...(selected
                      ? {
                          borderWidth: 1,
                          padding: 2,
                          borderColor: "black"
                        }
                      : {})
                  }}
                >
                  <RHImage
                    src={productSwatch.swatchImageLg.url}
                    alt={productSwatch.title}
                    aspectRatio={IMAGE_ASPECT_RATIO.swatch}
                  />
                </div>
                <Typography
                  className={classnames([
                    typographyClasses.rhBaseCaption,
                    "!mt-[8px]"
                  ])}
                  dangerouslySetInnerHTML={{
                    __html: productSwatchTitle
                  }}
                />
                {swatchStockStatus && (
                  <Typography
                    variant="body1"
                    className={classnames([
                      typographyClasses.rhBaseBody1,
                      typographyClasses.rhBaseBodyRoman
                    ])}
                    style={{
                      color: CATALOG_SALE_COLOR,
                      textTransform: "uppercase",
                      fontSize: "11px"
                    }}
                  >
                    {pageContent?.sale}
                  </Typography>
                )}
              </GridListTile>
            </Tooltip>
          );
        })}
      </GridList>
    </Grid>
  );
};

export default memoize(SwatchInfoDesktop);
