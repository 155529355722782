import React, { FC } from "react";
import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent
} from "utils/material-ui-core";
import Skeleton from "@material-ui/lab/Skeleton";
import RHImage from "component-rh-image";
import useMediaString from "hooks/useMediaString";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

export interface SwatchDetailProps {
  swatch?: ProductSwatch;
  productSwatchImage: Maybe<ProductImage>;
  onSelect?: (swatch: ProductSwatch) => void;
}

export const SwatchDetail: FC<SwatchDetailProps> = ({
  swatch,
  productSwatchImage
}) => {
  useMediaString();
  if (!swatch) {
    return (
      <Card elevation={0} square>
        <CardMedia>
          <Skeleton variant="rect" height={400} />
        </CardMedia>
        <CardContent>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </CardContent>
      </Card>
    );
  }

  const swatchHeroImage = swatch?.swatchImageLg
    ? swatch.swatchImageLg.url
    : productSwatchImage?.imageUrl;

  return (
    <Grid container spacing={2} id={"component-swatch-detail"}>
      {swatch?.swatchImageLg && productSwatchImage?.imageUrl && (
        <Grid item xs={12}>
          <RHImage
            style={{ objectFit: "contain" }}
            objectFit="contain"
            src={productSwatchImage.imageUrl}
            alt={swatch.title}
            aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <RHImage
          objectFit="contain"
          src={swatchHeroImage}
          alt={swatch.title}
          aspectRatio={IMAGE_ASPECT_RATIO["heroImage"]}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: swatch.optionCopy || swatch.title
          }}
        />
      </Grid>
    </Grid>
  );
};

SwatchDetail.defaultProps = {};

export default SwatchDetail;
