import SwatchDetailDialog from "dialog-swatch-detail";
import { useEnv } from "hooks/useEnv";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import useState from "hooks/useState";
import React, { CSSProperties, FC, useCallback } from "react";
import { InView } from "react-intersection-observer";
import { Grid } from "utils/material-ui-core";
import memoize from "utils/memoize";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import SwatchInfoMobile from "./SwatchInfoMobile";

import SwatchInfoDesktop from "./SwatchInfoDesktop";

export interface SwatchGroupsProps {
  swatchGroups: ProductSwatchGroup[];
  productSwatchImage: Maybe<ProductImage>;
  swatches: ("customSwatches" | "stockedSwatches")[];
  listItemClassName?: string;
  selectedOptionsFromLineItem?: { [key: string]: any };

  selectedSwatches: {
    property?: ProductSwatch;
  };
  onClick?: {
    (data: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }): void;
  };
  detail?: boolean;
  isCustomProduct?: boolean;
  data?: Product;
  applyCTAContent?: (
    style?: CSSProperties,
    ctaTitle?: String
  ) => React.ReactNode | void;
  tabValue?: number | undefined;
}

const SwatchGroups: FC<SwatchGroupsProps> = ({
  swatchGroups,
  productSwatchImage,
  swatches,
  selectedOptionsFromLineItem,
  selectedSwatches,
  listItemClassName = "",
  onClick,
  isCustomProduct,
  data,
  applyCTAContent = () => {},
  tabValue = undefined
}) => {
  const [selectedSwatch, setSelectedSwatch] = useState<
    ProductSwatch | undefined
  >(undefined);
  const [showSwatchDetailDialog, setShowSwatchDetailDialog] = useState(false);
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const env = useEnv();
  const [carouselIndex, setCarouselIndex] = useState(0);

  //only for custom rugs with trim colors
  const primaryOptionSelected =
    selectedSwatches[Object.keys(selectedSwatches)[0]];
  const secondaryOptionsExist =
    primaryOptionSelected?.relatedSwatchBeans.length !== 0;
  const secondaryOptionType =
    primaryOptionSelected?.relatedSwatchBeans[0]?.options[0]?.optionType;
  const secondarySwatchGroup = {
    swatchGroupIndex: 0,
    sortPriority: 10000,
    swatchGroupName: "Select Trim Color",
    groupMaterial: "Trim",
    stockedSwatches: primaryOptionSelected?.relatedSwatchBeans
  };

  const setSelection = useCallback(
    (selection: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }) => {
      setSelectedSwatch(selection.selectedSwatch);
      onClick?.(selection);
      if (!processEnvServer) {
        document.body.dispatchEvent(
          new CustomEvent("pre-pdp-click", {
            detail: {
              item: {
                action: "Swatch Item selection",
                label:
                  selection?.swatchGroup?.swatchGroupName +
                  " - " +
                  selection?.selectedSwatch?.title
              }
            }
          })
        );
      }
    },
    [onClick, selectedSwatch, carouselIndex]
  );

  //only for custom rugs with trim colors
  const setSecondarySelection = useCallback(
    (selection: {
      swatchGroup: ProductSwatchGroup;
      selectedSwatch: ProductSwatch;
    }) => {
      setSelectedSwatch(selection.selectedSwatch);
      onClick?.(selection);
      if (!processEnvServer) {
        document.body.dispatchEvent(
          new CustomEvent("pre-pdp-click", {
            detail: {
              item: {
                action: "Swatch Item selection",
                label:
                  selection?.swatchGroup?.swatchGroupName +
                  " - " +
                  selection?.selectedSwatch?.title
              }
            }
          })
        );
      }
    },
    [onClick]
  );

  const handlerIdCatcher = id => {
    setCarouselIndex(id);
  };

  const matchedSwatchId = React.useMemo(() => {
    return swatchGroups?.filter((swatchGroup, index) => {
      return (
        swatchGroup?.[swatches[0]]?.[carouselIndex]?.swatchId ===
        selectedSwatch?.swatchId
      );
    });
  }, [swatchGroups, swatches, carouselIndex, selectedSwatch]);

  const onDialogCloseCb = useCallback(() => setSelectedSwatch(undefined), []);

  return (
    <Grid container spacing={2}>
      <Hidden mdUp>
        <SwatchDetailDialog
          swatch={selectedSwatch}
          open={showSwatchDetailDialog && !!selectedSwatch}
          productSwatchImage={productSwatchImage}
          onClose={onDialogCloseCb}
        />
      </Hidden>

      {swatchGroups.map((swatchGroup, index) => {
        const productSwatches = swatches
          .reduce((acc, cur) => {
            const newElements = (swatchGroup[cur] || []).filter(s => {
              return !acc.find(el => el.swatchId === s.swatchId);
            });
            return [...newElements, ...acc];
          }, [] as ProductSwatch[])
          .sort((a, b) => a?.sortPriority - b?.sortPriority);
        if (!productSwatches.length) {
          return null;
        }

        return (
          <Grid
            id={"component-swatch-groups"}
            item
            xs={12}
            key={`swatch-info-mobile${swatchGroup.swatchGroupIndex}${swatchGroup.swatchGroupName}`}
            style={{ minHeight: 140, width: "100%" }}
          >
            {isSSRToggledWithCSR ? (
              <>
                <Hidden mdUp>
                  <Grid container spacing={2}>
                    <SwatchInfoMobile
                      listItemClassName={listItemClassName}
                      productSwatches={productSwatches}
                      swatchGroup={swatchGroup}
                      selectedSwatches={
                        productSwatches.some(s =>
                          Object.values(selectedSwatches).find(
                            sw => sw?.swatchId && sw?.swatchId === s.swatchId
                          )
                        )
                          ? selectedSwatches
                          : null
                      }
                      onClick={setSelection}
                      isCustomProduct={isCustomProduct}
                      setShowSwatchDetailDialog={setShowSwatchDetailDialog}
                    />
                    {primaryOptionSelected && secondaryOptionsExist && (
                      <SwatchInfoMobile
                        listItemClassName={listItemClassName}
                        productSwatches={
                          primaryOptionSelected?.relatedSwatchBeans
                        }
                        swatchGroup={secondarySwatchGroup}
                        selectedSwatches={
                          productSwatches.some(s =>
                            Object.values(selectedSwatches).find(
                              sw => sw?.swatchId && sw?.swatchId === s.swatchId
                            )
                          )
                            ? { [secondaryOptionType]: selectedSwatch }
                            : null
                        }
                        onClick={setSecondarySelection}
                        isCustomProduct={isCustomProduct}
                        setShowSwatchDetailDialog={setShowSwatchDetailDialog}
                      />
                    )}
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid container spacing={2}>
                    <SwatchInfoDesktop
                      productSwatches={productSwatches}
                      swatchGroup={swatchGroup}
                      selectedSwatches={selectedSwatches}
                      onClick={setSelection}
                      isCustomProduct={isCustomProduct}
                    />

                    {primaryOptionSelected && secondaryOptionsExist && (
                      <SwatchInfoDesktop
                        productSwatches={
                          primaryOptionSelected?.relatedSwatchBeans
                        }
                        swatchGroup={secondarySwatchGroup}
                        selectedSwatches={{
                          [secondaryOptionType]: selectedSwatch
                        }}
                        onClick={setSecondarySelection}
                        isCustomProduct={isCustomProduct}
                      />
                    )}
                  </Grid>
                </Hidden>
              </>
            ) : (
              <InView triggerOnce>
                {({ inView, ref, entry }) => (
                  <div ref={ref}>
                    {inView && entry?.isIntersecting && (
                      <>
                        <Hidden mdUp>
                          <Grid container spacing={2}>
                            <>
                              {
                                <>
                                  <SwatchInfoMobile
                                    listItemClassName={listItemClassName}
                                    productSwatches={productSwatches}
                                    swatchGroup={swatchGroup}
                                    selectedSwatches={
                                      productSwatches.some(s =>
                                        Object.values(selectedSwatches).find(
                                          sw =>
                                            sw?.swatchId &&
                                            sw?.swatchId === s.swatchId
                                        )
                                      )
                                        ? selectedSwatches
                                        : null
                                    }
                                    onClick={setSelection}
                                    isCustomProduct={isCustomProduct}
                                    setShowSwatchDetailDialog={
                                      setShowSwatchDetailDialog
                                    }
                                  />
                                  {primaryOptionSelected &&
                                    secondaryOptionsExist && (
                                      <SwatchInfoMobile
                                        listItemClassName={listItemClassName}
                                        productSwatches={
                                          primaryOptionSelected?.relatedSwatchBeans
                                        }
                                        swatchGroup={secondarySwatchGroup}
                                        selectedSwatches={
                                          productSwatches.some(s =>
                                            Object.values(
                                              selectedSwatches
                                            ).find(
                                              sw =>
                                                sw?.swatchId &&
                                                sw?.swatchId === s.swatchId
                                            )
                                          )
                                            ? {
                                                [secondaryOptionType]:
                                                  selectedSwatch
                                              }
                                            : null
                                        }
                                        onClick={setSecondarySelection}
                                        isCustomProduct={isCustomProduct}
                                        setShowSwatchDetailDialog={
                                          setShowSwatchDetailDialog
                                        }
                                      />
                                    )}
                                </>
                              }
                            </>
                          </Grid>
                        </Hidden>
                        <Hidden smDown>
                          <Grid container spacing={2}>
                            {
                              <>
                                <SwatchInfoDesktop
                                  productSwatches={productSwatches}
                                  swatchGroup={swatchGroup}
                                  selectedSwatches={selectedSwatches}
                                  onClick={setSelection}
                                  isCustomProduct={isCustomProduct}
                                />
                                {primaryOptionSelected &&
                                  secondaryOptionsExist && (
                                    <SwatchInfoDesktop
                                      productSwatches={
                                        primaryOptionSelected?.relatedSwatchBeans
                                      }
                                      swatchGroup={secondarySwatchGroup}
                                      selectedSwatches={{
                                        [secondaryOptionType]: selectedSwatch
                                      }}
                                      onClick={setSecondarySelection}
                                      isCustomProduct={isCustomProduct}
                                    />
                                  )}
                              </>
                            }
                          </Grid>
                        </Hidden>
                      </>
                    )}
                  </div>
                )}
              </InView>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

SwatchGroups.defaultProps = {};

export default memoize(SwatchGroups);
