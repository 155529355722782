import React, {
  CSSProperties,
  FC,
  useState,
  ReactElement,
  useCallback
} from "react";
import {
  Tooltip,
  IconButton,
  makeStyles,
  createStyles,
  Theme
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import {
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  BREAKPOINT_SM
} from "utils/constants";
import RHInfoIconV2 from "icon-info-v2";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipInfo: {
      backgroundColor: "white",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,0.31)",
      maxWidth: "665px !important",
      padding: "24px",
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        maxWidth: "343px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        maxWidth: "343px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        maxWidth: "600px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        maxWidth: "700px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        maxWidth: "800px !important"
      }
    },
    arrow: {
      fontSize: 35,
      color: "white",
      "&::before": {
        boxShadow: "0 1px 3px 0 rgba(0,0,0,0.31)"
      }
    }
  })
);
export interface SwatchTooltipProps {
  copy: string | ReactElement;
  style?: CSSProperties;
  className?: string;
  placement?: any;
  tooltipLabelContext?: string | ReactElement;
  toggleHandleCallback?: Function;
  swatchGroupName?: string | null;
  handleSelection?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  productSwatch?: any;
  selection?: any | null;
  isConfigurator?: boolean;
}

const SwatchToolTip: FC<SwatchTooltipProps> = memoize(
  ({
    copy,
    style,
    className,
    placement = "top",
    tooltipLabelContext,
    toggleHandleCallback
  }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const handleCloseTooltip = useCallback(() => {
      setIsOpen(false);
      toggleHandleCallback && toggleHandleCallback(false);
    }, []);
    const handleCloseTooltipOpen = useCallback(() => {
      setIsOpen(true);
      toggleHandleCallback && toggleHandleCallback(true);
    }, []);

    return (
      <Tooltip
        id="swatch_ToolTip"
        data-testid="swatch-tooltip"
        placement={placement}
        className={className}
        classes={{
          tooltip: classes.tooltipInfo,
          arrow: classes.arrow
        }}
        style={style}
        open={isOpen}
        title={copy}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        arrow
        onBlur={handleCloseTooltip}
      >
        <IconButton onClick={handleCloseTooltipOpen} style={{ padding: 0 }}>
          {tooltipLabelContext ? (
            tooltipLabelContext
          ) : (
            <RHInfoIconV2 style={{ color: "#000" }} />
          )}
        </IconButton>
      </Tooltip>
    );
  }
);
SwatchToolTip.defaultProps = {
  copy: ""
};

export default SwatchToolTip;
